import { template as template_9695af7e18bb452f8aa5c7e7fe70d83a } from "@ember/template-compiler";
const FKText = template_9695af7e18bb452f8aa5c7e7fe70d83a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
