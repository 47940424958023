import { template as template_717469cf4bcc418794bd444f5c84984c } from "@ember/template-compiler";
const WelcomeHeader = template_717469cf4bcc418794bd444f5c84984c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
