import { template as template_7d8be8f3ecb847d1a6f9e6011db31fc0 } from "@ember/template-compiler";
import icon from "discourse/helpers/d-icon";
const InputTip = template_7d8be8f3ecb847d1a6f9e6011db31fc0(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
